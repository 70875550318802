.modal-content {
    background-color: #000;
    -webkit-box-shadow: 0px 0px 25px 0px rgba(255, 255, 255, 0.1);
    -moz-box-shadow: 0px 0px 25px 0px rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 25px 0px rgba(255, 255, 255, 0.1);
}

.modal-content .btn-close {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill='%23fff' d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
    max-width: 10px;
    max-height: 10px;
}

.modal-content .modal-body {
    font-size: 16px;
}

.modal {
    background-color: rgba(0, 0, 0, 0.5);
}

.cc-payment svg {
    display: block;
    max-width: 50px;
    margin: 15px auto;
}

.animate-spin {
    animation: spin 1s linear infinite;
}

.the-nfts .card {
    cursor: pointer;
}

.iframePreloader {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.offcanvas-body {
    padding: 0;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media screen and (min-width: 800px) {
    .modal-dialog {
        max-width: 600px !important;
    }
}

.browse-time-left ul {
    padding: 0;
	margin: 0px;
    list-style: none;
	display: flex;
	justify-content: center;
}
.browse-time-left ul > li {
	float: left;
	margin-right: 10px;
	padding-right: 10px;
	font-size: 12px;
	text-transform: uppercase;
	color: #CCC;
}
.browse-time-left ul > li:last-child {
	margin-right: 0px;
	padding-right: 0px;
}
.browse-time-left ul > li > span {
	font-size: 26px;
	font-weight: 700;
	display: block;
	font-family: "Mulish", sans-serif;
	line-height: normal;
	color: #FFF;
}